import React, { useState, useEffect } from "react";
import "./talkexp.scss";
import Slider from "react-slick";
import star from "../assets/Star.svg";
import "../pages/service.scss";
import "./modal.scss";
import { client } from "../client";
import { FiPhoneCall } from "react-icons/fi";
import { GrLinkedin } from "react-icons/gr";

const TalkExp = () => {
  const [exp, setExp] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type=="experts"]{
      name,
      "imageUrl":image.asset->url,
      contact,
      link,
      role
    }`
      )
      .then((data) => setExp(data)).catch(console.error);
  }, []);
  const [popUpContent, setPopupContent] = useState([]);
  const [poptoggle, setPoptoggle] = useState(false);
  const changeContent = (exp) => {
    setPopupContent([exp]);
    setPoptoggle(!poptoggle);
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="talkexpo">
      <div className="head">
        <span>
          <span style={{ color: "white" }}>Talk</span>
          <img src={star} alt="" />
        </span>
        to Our Experts
      </div>
      <div className="foot">
        <Slider {...settings}>
          {exp.map((member, index) => {
            return (
              <div
                className="card"
                style={{ width: "18rem", height: "500px" }}
                key={index}
              >
                <img src={member.imageUrl} className="card-img-top" alt="..." />
                <div className="card-body">
                  <div className="name">{member.name}</div>
                  <div className="role">{member.role}</div>
                  <button onClick={() => changeContent(member)}>
                    Let's Talk
                  </button>
                </div>
              </div>
            );
          })}
        </Slider>
        {poptoggle && (
          <div className="popupContainer" onClick={changeContent}>
            <div className="popupBody" onClick={(e) => e.stopPropagation()}>
              <div className="popupHeader">
                <button onClick={() => {setPoptoggle(false)}}>x</button>
              </div>
              <div className="popUpContent">
                {popUpContent.map((pop, index) => {
                  return (
                    <div className="popContainer" key={index}>
                      <div className="heading">
                        <img className="profile" src={pop.imageUrl} alt="" />
                        <div className="name">{pop.name}</div>
                        <div className="role">{pop.role}</div>
                        <div className="contact">
                          <a href={pop.link} id="linked" target="_blank">{<GrLinkedin />}</a>
                          <a href={`tel:${pop.contact}`}>{<FiPhoneCall />}</a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TalkExp;
