import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import "./join.scss";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import vector from "../assets/vector.png";
import Footer from "../Components/Footer";
import star2 from "../assets/Star.svg";
import "../pages/cont.scss";
import Slider from "react-slick";
import { storage } from "./firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import axios from "axios";

const JoinUs = () => {
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [num, setNum] = useState("");
  const [email, setEmail] = useState("");
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");
  const [port, setPort] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const handleSubmit = async (e) => {
    const maxSize = 3072 // 3mb
    e.preventDefault();
    if (file === null) {
      return
    }
    if(file.size/1024 > maxSize){
      alert("file size is greater then 3mb")
      return
    }
    
    const fileRef = ref(storage, `Resume/${name + v4()}`);
    await uploadBytes(fileRef, file)
      .then(() => {
        alert("Resume uploaded");
      })
      .catch(console.error);
    await getDownloadURL(fileRef)
      .then((url) => {
        setFileUrl(url);
      })
      .catch(console.error);
  };
  useEffect(() => {
    if (fileUrl !== "") {
      const data = {
        Name: name,
        Number: num,
        Email: email,
        HowSoon: text1,
        ProfileApply: text2,
        RemoteOrOnsite: text3,
        Resume: fileUrl,
        Portfolio: port,
      };
      console.log(data);
      axios
        .post("https://sheetdb.io/api/v1/po7l231wemew8", data)
        .then((response) => {
          console.log(response)
        });
      setFileUrl("");
      setEmail("");
      setName("");
      setNum("");
      setText1("");
      setText2("");
      setText3("");
      setFile(null);
    }
  }, [fileUrl, email, name, num, text1, text2, text3]);
  const dis = true;
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);
  const Text = [
    {
      text: "Creative Thinker",
    },
    {
      text: "Problem Solvers",
    },
    {
      text: "Strategic Planners",
    },
  ];
  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    pauseOnHover: false,
    cssEase: "linear",
    speed: 300,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="joinus">
      <Navbar />
      <div className="Text">
        <div className="head" data-aos="zoom-in">
          Join our
          <br />
          <span>
            WINNING TEAM
            <img src={star2} alt="" data-aos="fade-left" />
          </span>
        </div>
      </div>
      <div className="team">
        <div className="heading">we are a community of</div>
        <div className="teampic"></div>
        <div className="commun">
          <div className="inner">
            <Slider {...settings}>
              {Text.map((item, index) => {
                return (
                  <div key={index}>
                    {/* <div className="imag"><img src={star} alt="" /></div>  */}
                    <span>{item.text}</span>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
      <div className="block">
        <div className="text">
          We are always looking for talented individuals to join our team
          whether you are a recent graduate or an experienced professional, we
          have opportunities for you to learn, grow, and make a real impact.
        </div>
      </div>
      <div className="form">
        <form action="" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Name"
            id="name"
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <input
            type="tel"
            name=""
            id="number"
            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
            placeholder="Contact Number"
            onChange={(event) => {
              setNum(event.target.value);
            }}
            required
          />
          <input
            type="email"
            name=""
            id="email"
            placeholder="Email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            required
          />
          <input
            type="text"
            name=""
            id="text1"
            placeholder="How soon can you join ?"
            onChange={(event) => {
              setText1(event.target.value);
            }}
            required
          />
          <input
            type="text"
            name=""
            id="text2"
            placeholder="What profile do you want to apply for?"
            onChange={(event) => {
              setText2(event.target.value);
            }}
            required
          />
          <input
            type="text"
            name=""
            id="text3"
            placeholder="Onsite/Remote"
            onChange={(event) => {
              setText3(event.target.value);
            }}
            required
          />
          Attach your updated Resume
          <input
            type="file"
            name=""
            id="resume"
            placeholder="AttaResumech your updated "
            title = "Attach your updated Resume"
            onChange={(event) => {
              setFile(event.target.files[0]);
            }}
            required
          />
          
          <input
            type="url"
            name=""
            id="port"
            placeholder="Portfolio Link"
            onChange={(event) => {
              setPort(event.target.value);
            }}
          />
          <button>
            Join our Team
            <img src={vector} alt="" />
          </button>
        </form>
      </div>
      <Footer dis />
    </div>
  );
};

export default JoinUs;
