import { Routes, Route } from 'react-router-dom';
import './App.css';
import AboutUs from './pages/AboutUs';
import Home from './pages/Home'
import Service from './pages/Service'
import JoinUs from './pages/JoinUs'
import Project from './pages/Project'
import ContactUs from './pages/ContactUs'
import './Components/style.scss'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Singlepost from './pages/Blogs/Singlepost';


function App() {
  return (
    <Routes>
      <Route path='/Home'/>
      <Route index element={<Home/>}/>
      <Route path='AboutUs' element={<AboutUs/>}/>
      <Route path='Service' element={<Service/>}/>
      <Route path='Project' element={<Project/>}/>
      <Route path='JoinUs' element={<JoinUs/>}/>
      <Route path='ContactUs' element={<ContactUs/>}/>
      <Route path='blog' />
      <Route path='blog/:slug' element={<Singlepost/>}/>
    </Routes>
  );
}

export default App;
