import React from 'react'
import Navbar from '../Components/Navbar'
import star from '../assets/Star.svg'
import {Link} from 'react-router-dom'
import vector from '../assets/vector.png'
import star4 from '../assets/Star4.svg'
import ProjectSlider from '../Components/ProjectSlider'
import Blogs from '../Components/Blogs'
import LogoSlider from '../Components/LogoSlider'
import ServeComp from '../Components/ServeComp'
import Footer from '../Components/Footer'
import AOS from 'aos'
import { useEffect } from 'react'
import 'aos/dist/aos.css'
import TalkExp from '../Components/TalkExp'
import Testimonial from '../Components/Testimonial'
import FadeInOnScroll from '../Components/FadeInOnScroll'
const Home = () => {
  useEffect(() => {
    AOS.init({duration:1000})
  }, )
  return (
    <div className='Wrapper'>
      <Navbar/>
    <div className="Home" id='Home'>
      <div className="Text">
        <p><span>UNLOCK<img src={star} alt="" /></span>your online growth potential</p>        
      </div>
      <div className="philink">
        <div className="joinlink">
          <p>Let's get started</p>
          <div><Link to='/ContactUs'>Start a project</Link><img src={vector} alt="" /></div> 
        </div>
        <div className="philos">
          <div>Our Philosophy :</div>
          <p><span>GOOD DESIGN</span> is good business</p>
          <div className='author' >- T. J. Watson Jr.</div>
      </div>
        <img src={star4} alt="" className='star4'/>
      </div>
      <ProjectSlider/>
      <LogoSlider/>
      <div className="block">
        <hr/>
        <div className="one">
          <p data-aos="slide-left">Let us help</p>
        </div>
        <hr/>
        <div className="two">
          <p data-aos="">You Scale Your</p>
        </div>
        <hr/>
        <div className="three">
          <p data-aos="slide-right">Business</p>
        </div>
        <hr/>
      </div>
      <ServeComp/>
      <Testimonial/>
      <FadeInOnScroll/>
      <TalkExp/>
      <Blogs/>
      <Footer/>
    </div>
    </div>
    
  )
}

export default Home