import React from 'react'
import {Link , useParams} from 'react-router-dom'
import { client } from '../../client'
import { useState,useEffect } from 'react'
import Navbar from '../../Components/Navbar'
import "../Blogs/singlepost.scss"
import BlockContent from '@sanity/block-content-to-react'
import Blogs from '../../Components/Blogs'
import Footer from '../../Components/Footer'
import fb from '../../assets/facebook.svg'
import linkedin from '../../assets/linkedin.svg'
import twitter from '../../assets/twitter.svg'
import copylink from '../../assets/copy_link.svg'

const Singlepost = () => {
    const [singlePost,setSinglePost] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const {slug} = useParams()
    const link = encodeURI(window.location.href)
    const link2= "https://tacklesite.netlify.app/"


    useEffect(() => {
        client.fetch(
            `*[slug.current =="${slug}"]
            {
                title,
                slug,
                body,
                "authorRole":author->role,
                "authImage":author->image.asset->url,
                "authorName":author->name,
                publishedAt,
                "imageUrl":mainImage.asset->url
            }`

        ).then((data)=>setSinglePost(data[0])).catch(console.error)
        setIsLoading(false)
    }, [slug])
    function copyToClipboard() {
      const el = document.createElement('textarea');
      el.value = window.location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert('Link copied to clipboard!');
    }
    console.log(singlePost)
  return (
    <div>
        <Navbar/>
        {isLoading ? 
        <div className="loading"> <h1>Loading...</h1></div>:
        <div className="Container">
          <div className="header">
            <img src={singlePost.imageUrl} alt="Image" />
          </div>
          <div className="dat">
            {singlePost.publishedAt}
          </div>
          <div className="title">
            <h1>{singlePost.title}</h1>
          </div>
          <div className="authDetail">
            <div className="left">
              <img src={singlePost.authImage} alt="" />
            </div>
            <div className="right">
              <div className="name">{singlePost.authorName}</div>
              <div className="role">{singlePost.authorRole}</div>
            </div>  
          </div>
          <div className="blogContent">
              <BlockContent blocks={singlePost.body}
              projectId="s3q66y0a" dataset="production"/>
            </div>
        </div>
        }
        <div className="word1">
        <span>Share the words</span>
        </div>
        <div className="share1">
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${link2}blog/${slug}`} target="_blank" ><img src={fb} alt="" /></a>
        <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${link2}blog/${slug}`} target="_blank"><img src={linkedin} alt="" /></a>
        <a href={`https://twitter.com/intent/tweet?url=${link2}blog/${slug}&amp;text=${slug}!&amp;via=${singlePost.authorName}`} target="_blank"><img src={twitter} alt="" /></a>
        <a onClick={copyToClipboard}><img src={copylink} alt="" /></a>
        </div>
        <div className="next">
          <span>Next reads</span>
        </div>
        <Blogs/>
        <Footer/>

    </div>
  )
}

export default Singlepost