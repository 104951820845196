import React from 'react'
import Navbar from '../Components/Navbar'
import './service.scss'
import AOS from 'aos'
import { useEffect } from 'react'
import 'aos/dist/aos.css'
import ServeComp from '../Components/ServeComp'
import Footer from '../Components/Footer'
import ProjectSlider from '../Components/ProjectSlider'
import star from '../assets/Star.svg'
import TalkExp from '../Components/TalkExp'
import Testimonial from '../Components/Testimonial'
import Blogs from '../Components/Blogs'
import FadeInOnScroll from '../Components/FadeInOnScroll'

const Service= () => {
  useEffect(() => {
    AOS.init({duration:500})
  }, [])
  return (
    <div className="service">
        <Navbar/>
        <div className="Text">
        <div className='head' data-aos="zoom-in"><span>Creative<img src={star} alt="" data-aos="fade-left" /></span> Marketing Solutions for your Business</div>
        </div>
        <div className="stratcontent">
          <div className="content">From strategy and concept development to execution 
and measurement, we offer a complete range of services 
to help you achieve your business goals. </div>
        </div>
        <ServeComp/>
        <ProjectSlider/>
        <FadeInOnScroll/>
        <TalkExp />
        <Testimonial/>
        <Blogs/>
        <Footer/>
  
    </div>
    
  )
}

export default Service