import React, { useState,useEffect } from 'react'
import './logoslider.scss'
import { client } from '../client'
// import client from '../assets/momo.png'

const LogoSlider = () => {
	const [logo,setLogo] = useState([])
	useEffect(() => {
		  client.fetch(`*[_type == "logo"]{
			"LogoUrl": logoimage.asset->url
		  }`).then((data)=>setLogo(data)).catch(console.error)
	  }, [])
	const m=logo.length;
  return (
	<div className="clients">
		<div>Our Clients :</div>
    <div className="slider">
	<div className="slide-track" style={{width: `calc(250px * ${m})`}}>
		{
			logo.map((item,index)=>{
				return(
				<div className="slide" key={index}>
					<img src={item.LogoUrl} alt="" />
				</div>
				)
			})
		}
	</div>
</div>
</div>
  )
}

export default LogoSlider