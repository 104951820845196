import { useEffect, useRef } from "react";
import "./fade.scss";
import star from "../assets/Star2.svg";

function FadeInOnScroll() {
  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.7,
  };

  const observerRef = useRef(null);

  function observerCallback(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // fade in observed elements that are in view
        entry.target.classList.replace("fadeOut", "fadeIn");
      } else {
        // fade out observed elements that are not in view
        entry.target.classList.replace("fadeIn", "fadeOut");
      }
    });
  }

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      observerCallback,
      observerOptions
    );
    const fadeElms = document.querySelectorAll(".fade");
    fadeElms.forEach((el) => observerRef.current.observe(el));

    // cleanup function
    return () => observerRef.current.disconnect();
  }, []);

  return (
    <div className="fading">
      <div className="headpara">
        <p>
          Our experienced team will work with you to develop and execute a
          custom marketing plan that drives real results.
        </p>
      </div>
      <div className="container2">
        <div className="space"></div>
        <div className="fade fadeOut start">
          <div className="heading">
            <img src={star} alt="" />
            <span>Discovery</span>
          </div>
          <div className="content">
            We start by learning about your business, your goals, your target
            audience, and your current marketing efforts. We conduct research
            and analysis to gain a deep understanding of your industry and your
            competitors.
          </div>
        </div>
        <div className="fade fadeOut">
          <div className="heading">
            <img src={star} alt="" />
            <span>Strategy</span>
          </div>
          <div className="content">
            Using the information gathered during the discovery phase, we
            develop a comprehensive marketing strategy that is tailored to your
            specific needs. We will work with you to identify the most effective
            tactics and channels to reach your target audience and achieve your
            business goals.
          </div>
        </div>
        <div className="fade fadeOut">
          <div className="heading">
            <img src={star} alt="" />
            <span>Execution</span>
          </div>
          <div className="content">
            Once the strategy is in place, we will work with you to execute the
            plan. We will develop and implement marketing campaigns that are
            designed to reach your target audience and drive measurable results.
          </div>
        </div>
        <div className="fade fadeOut">
          <div className="heading">
            <img src={star} alt="" />
            <span>Optimization</span>
          </div>
          <div className="content">
            We will continuously measure the performance of our campaigns and
            make adjustments as needed to optimize results. We will also use
            data analytics to understand how your target audience is interacting
            with your brand, and identify any opportunities for improvement.
          </div>
        </div>
        <div className="fade fadeOut last">
          <div className="heading">
            <img src={star} alt="" />
            <span>Support</span>
          </div>
          <div className="content">
            We will be with you all the way, providing continuous support to
            ensure that your business is always moving in the right direction.
          </div>
        </div>
        <div className="space"></div>
      </div>
    </div>
  );
}

export default FadeInOnScroll;
