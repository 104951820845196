import React,{useState,useEffect} from "react";
import star from "../assets/Star2.svg";
import Slider from "react-slick";
import '../Components/Cardcss.scss'
import { client } from "../client"
const ProjectSlider = () => {
  const [proj,setProj] = useState([])
  useEffect(() => {
    client.fetch(`*[_type == "project"]{
      "Viewpdf": pdf.asset->url,
      "imageUrl": image.asset->url,
    }`).then((data)=>setProj(data)).catch(console.error)
  }, [])
  

  const settings = {
    dots: true,
    arrows:true,
    infinite: true,
    slidesToShow: 3, 
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };

  return (
    <div className="projSlider">
      <div className="left">
        <img src={star} alt="" />
        <span>Projects</span>
      </div>
      <div className="right">
        <Slider {...settings}>
          {proj.map((item,index)=>{
            return(
                  <div className="card" key={index}>
                    <img src={item.imageUrl} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <button><a href={item.Viewpdf} target="_blank"> View </a></button>
                    </div>
                  </div>
            )
          })}
        </Slider>
      </div>
    </div>
  );
};

export default ProjectSlider;
