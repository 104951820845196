import React from 'react'
import Navbar from '../Components/Navbar'
import './proj.scss'
import AOS from 'aos'
import { useEffect } from 'react'
import 'aos/dist/aos.css'
import star from '../assets/Star.svg'
import LogoSlider from '../Components/LogoSlider'
import TalkExp from '../Components/TalkExp'
import Footer from '../Components/Footer'
import Slider from 'react-slick'
import star2 from '../assets/Star2.svg'
import { useState,useRef } from 'react'
import { client } from '../client'
import Testimonial from '../Components/Testimonial'
import '../Components/testi2.scss'
import Blogs from '../Components/Blogs'

const Project = () => {
  const [proj,setProj] = useState([])
  const slider=useRef(null)
  useEffect(() => {
    AOS.init({duration:500})
      client.fetch(`*[_type == "project"]{
        name,
        message,
        "Viewpdf": pdf.asset->url,
        "imageUrl": image.asset->url,
      }`).then((data)=>setProj(data)).catch(console.error)
  }, [])
  const settings = {
    // fade: true,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  
  return (
    <div className="project">
        <Navbar/>
        <div className="Text">
          <div className='head' data-aos="zoom-in"><span>See our work<img src={star} alt="" data-aos="fade-left" /></span> <br/> Check out Our Recent Projects</div>
        </div>
        <div className="team">
          <div className="heading">We take pride in our ability to deliver results for our clients. 
Take a look at some of our recent projects to see the creative 
solutions we've developed and the measurable success 
we've achieved.</div>
        <div className="wrap">
          <Slider {...settings} ref={slider} >
            {
              proj?
              proj.map((item,index)=>{
                return (
                  <div className="wrap2" key={index} >
                  <div className="projectSlide" >
                  <div className="left">
                  <div className="up">
                        <div className='heading'><img src={star2} alt="" /><span>{item.name}</span></div>
                        <div className='message'>{item.message}</div>
                        </div>
                  </div>
                  <div className="right">
                  <div className="card">
                        <img src={item.imageUrl} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <button> <a href={item.Viewpdf} target="_blank"> View </a></button>
                        </div>
                      </div>
                  </div>
                </div>
                </div>
                )
              }): <span>Error</span>
            }
            </Slider> 
            </div>  
          </div> 
          <div className="low">
          <button onClick={()=>{slider.current.slickPrev();}}>Prev</button>
          <button onClick={()=>{slider.current.slickNext();}}>Next</button>
          </div>
        <LogoSlider/>
        <TalkExp/>
        <Testimonial/>
        <Blogs/>
        <Footer/>
    </div>
    
  )
}

export default Project