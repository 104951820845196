import React from 'react'
import Navbar from '../Components/Navbar'
import Testimonial from '../Components/Testimonial'
import Footer from '../Components/Footer'
import './about.scss'
import star from '../assets/Star.svg'
import AOS from 'aos'
import { useEffect ,useState} from 'react'
import 'aos/dist/aos.css'
import Slider from 'react-slick'
import { client } from '../client'
import Blogs from '../Components/Blogs'
import Scollanimsec from '../Components/Scollanimsec'

const AboutUs = () => { 
  const [team,setTeam] = useState([])
  useEffect(() => {
    AOS.init({duration:500})  
      client.fetch(`*[_type =="team"]{
        "imageUrl": image.asset->url,
          name,
          id,
          role
      }`).then((value)=>setTeam(value))
  }, [])

  console.log(team)
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    pauseOnHover: false,
    cssEase: "linear",
    arrows:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };
  
  return (
    <div className="aboutus" id="aboutus">
        <Navbar/>
        <div className="Text">
          <div className='head' data-aos="zoom-in"><span>UNLEASH<img src={star} alt="" data-aos="fade-left" /></span>the Power of Creativity</div>
        </div>
        <div className="team">
          <div className="heading">Meet the Team Behind Tackle</div>
          <div className="teampic">
            <Slider {...settings}>
              { team ?
                team.map((member)=>{
                  return(
                    <div className="card" key={member.id}>
                    <img src={member.imageUrl} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <div className="name">{member.name}</div>
                      <div className="role">{member.role}</div>
                    </div>
                  </div>
                  )
                }): <span>error</span>
              }
            </Slider>
          </div>
        </div>
        <Scollanimsec/>
        <Testimonial/>
        <Blogs/>
        <Footer/>
    </div>
    
    
    
  )
}

export default AboutUs

