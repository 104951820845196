import React from "react";
import star2 from "../assets/Star2.svg";
import Slider from "react-slick";
import { client } from "../client";
import { useState, useEffect } from "react";
import { useRef } from "react";
import AOS from 'aos'
import 'aos/dist/aos.css'
const Testimonial = () => {
  const [test, setTest] = useState([]);
  const slider= useRef(null)
  useEffect(() => {
    AOS.init({duration:2000})
      client
        .fetch(
          `*[_type == "testimonial"]{
            client,
            message,
            "videoUrl":video.asset->url
        }`
        )
        .then((data) => setTest(data)).catch((error)=>(console.log(error)))
  }, []);
  const settings = {
    // fade: true,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="testimonial" data-aos="fade-up">
      <div className="head" >
        <img src={star2} alt="" />
        <span>Hear what our clients has to say </span>
      </div>
      <div className="leg">
        <Slider ref={slider} {...settings}>
          {test ? (
            test.map((item, index) => {
              console.log(item)
              return (
                <div className="foot" key={index}>
                  <div className="foot2" >
                    <div className="left">
                      <div className="heading">{item.client}</div>
                      <div className="message">{item.message}</div>
                    </div>
                    <div className="right">
                      <video controls  src={item.videoUrl} loop muted >
                      </video>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <span>error</span>
          )}
        </Slider>
        <div className="btns">
          <button
            onClick={() => {
              slider.current.slickPrev();
            }}
          >
            Prev
          </button>
          <button
            onClick={() => {
              slider.current.slickNext();
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
