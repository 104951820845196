import React from 'react'
import {Link} from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import './Nav.scss'
import { useEffect } from 'react';


const Navslider = () => {
    let location = useLocation();
    useEffect(() => {
    }, [location])
    
    const handleRefresh=()=>{
        const p=document.getElementById("inner")
        p.classList.toggle("openInner")
        if(location.pathname==="/" || location.pathname === "/AboutUs" || location.pathname === "/Project" || location.pathname === "/Service" ||location.pathname === "/JoinUs"|| location.pathname === "/ContactUs" ){
            const ele2=document.getElementById("slide");
            ele2.style.display="none"  
        }        
      }
    const myFunction=(x)=> {
        if (!x.matches) {
            const el=document.getElementById("slide");
            if(el!=null){
                const q=document.getElementById("inner")
                q.classList.toggle("openInner")
                el.style.display="none"
            }
        } 
    }
    var x = window.matchMedia("(max-width: 1093px)")
    myFunction(x)
    // x.addListener(myFunction)
  return (
    <div className="slide" id="slide">
        <Link to='/' onClick={handleRefresh}>Home</Link>
        <Link to='/AboutUs' onClick={handleRefresh}>About Us</Link>
        <Link to='/Service' onClick={handleRefresh}>Services</Link>
        <Link to='/Project' onClick={handleRefresh}>Projects</Link>
        <Link to='/JoinUs' onClick={handleRefresh}>Join Us</Link>
        <Link to='/ContactUs' onClick={handleRefresh}>Contact us</Link>
    </div>
  )
}

export default Navslider