import React from "react";
import star from "../assets/Star.svg";
import "./Footerstyle.scss";
import { Link } from "react-router-dom";
import vector from "../assets/vector.png";
import logo from "../assets/logo.svg";
import { useEffect } from "react";
import "aos/dist/aos.css";
import Aos from "aos";
import fb from '../assets/footerLogo/facebook.svg'
import inst from '../assets/footerLogo/instagram.svg'
import linkdin from '../assets/footerLogo/linkedin.svg'
import pin from '../assets/footerLogo/pinterest.svg'
const Footer = ({ dis }) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className={`footer ${dis ? "footernone" : ""}`}>
        <div className={`upper ${dis ? "none" : ""}`}>
          <div id="one">
            <p>
              <span>
                READY
                <img src={star} alt="" data-aos="zoom-in" />
              </span>
              to take your marketing to the next level?
            </p>
            <div>
              <span className="link">
                <Link to="/ContactUs">Start a project</Link>
                <img src={vector} alt="" />
              </span>
            </div>
          </div>
        </div>
        <div className={`lower ${dis ? "under" : ""}`}>
          <div className="block1">
            <div className="upperblock">
              <span>About Us</span>
              <p>
                Tackle focuses on providing innovative and excellent services in
                branding and advertising.
              </p>
            </div>
            <div className="lowerblock">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="block2">
            <div className="upperblock">
              <span>Contact Us</span>
              <a
                href="https://goo.gl/maps/7D6MmoL7vj58krdDA"
                target="_blank"
              >
                <p>
                  115/12b, Halwai ki bhagichi, Avas Vikas Colony, Lohamandi,
                  Agra, Uttar Pradesh 282007
                </p>
              </a>
            </div>
            <div className="lowerblock">
              <span>
                <a href={`tel:+91 76177 07703`}>+91 76177 07703</a>
              </span>
              <span id="sec">
                <a href={`tel:+91 8791056191`}>+91 8791056191</a>
              </span>
              <span id="email">
                {" "}
                <a href="mailto: marketing@itstackle.com">
                  marketing@itstackle.com
                </a>
              </span>
            </div>
          </div>
          <div className="block3">
            <span>Quick links</span>
            <Link to="/">Home</Link>
            <Link to="/AboutUs#aboutus">About Us</Link>
            <Link to="/Service">Services</Link>
            <Link to="/Project">Project</Link>
            <Link to="/JoinUs">Join Us</Link>
            <Link to="/ContactUs">Contact Us</Link>
          </div>
          <div className="block4">
            <div><a href="https://www.facebook.com/itstackle/ "><img src={fb} alt="" /></a></div>
            <div><a href="https://www.instagram.com/itstackle/"><img src={inst} alt="" /></a></div>
            <div><a href="https://www.linkedin.com/company/itstackle/"><img src={linkdin} alt="" /></a></div>
            <div><a href="https://in.pinterest.com/itstackle/"><img src={pin} alt="" /></a></div>
          </div>
          <div className="logo">
            <img src={logo} alt="" />
          </div>
        </div>
      </div>
      <div className="copyrights">
        <span>@2023 Tackle Media | All Rights Reserved</span>
      </div>
    </>
  );
};

export default Footer;
