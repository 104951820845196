import { useEffect, useRef } from "react";
import "./fade.scss";
import star from "../assets/Star2.svg";

function Scollanimsec() {
  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.7,
  };

  const observerRef = useRef(null);

  function observerCallback(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // fade in observed elements that are in view
        entry.target.classList.replace("fadeOut", "fadeIn");
      } else {
        // fade out observed elements that are not in view
        entry.target.classList.replace("fadeIn", "fadeOut");
      }
    });
  }

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      observerCallback,
      observerOptions
    );
    const fadeElms = document.querySelectorAll(".fade");
    fadeElms.forEach((el) => observerRef.current.observe(el));

    // cleanup function
    return () => observerRef.current.disconnect();
  }, []);

  return (
    <div className="fading">
      <div className="container1">
        <div className="space"></div>
        <div className="fade fadeOut start1">
          <div className="heading">
            <img src={star} alt="" />
            <span>Vision</span>
          </div>
          <div className="content">
            Our vision is to be the leading creative marketing agency, known for
            delivering innovative and impactful campaigns that drive measurable
            results for our clients.
          </div>
        </div>
        <div className="fade fadeOut">
          <div className="heading">
            <img src={star} alt="" />
            <span>Mission</span>
          </div>
          <div className="content">
            Our mission is to partner with our clients to understand their
            unique needs and goals, and to craft and execute marketing
            strategies that drive real business growth.
          </div>
        </div>
        <div className="fade fadeOut last">
          <div className="heading">
            <img src={star} alt="" />
            <span>Values</span>
          </div>
          <div className="content">
            We are constantly seeking new and better ways to achieve our
            clients' goals. We believe that the best work is done when we work
            together with our clients as a team.
          </div>
        </div>
        <div className="space"></div>
      </div>
    </div>
  );
}

export default Scollanimsec;
