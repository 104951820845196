import {createClient} from '@sanity/client'
// Import using ESM URL imports in environments that supports it:
// import {createClient} from 'https://esm.sh/@sanity/client'

export const client = createClient({
  projectId: 's3q66y0a',
  dataset: 'production', 
  apiVersion: '2022-01-12', 
  useCdn:true
})
