import React from 'react'
import img1 from '../assets/image40.svg'
import img2 from '../assets/image41.svg'
import img3 from '../assets/image42.svg'
import star from "../assets/Star2.svg";
import './Servcomp.scss'
import AOS from 'aos'
import { useEffect } from 'react'
import 'aos/dist/aos.css'

const ServeComp = () => {
    useEffect(() => {
      AOS.init({duration:2000})
    },[])
    
  return (
    <div className="servComp">
        <div className="upper">
            <img src={star} alt="" />
            <span>Services</span>
        </div>
        <div className="lower">
            <div className="img1" data-aos="fade-right">
                <img src={img1} alt="" />
            </div>
            <div className="img2" data-aos="zoom-in">
                <img src={img2} alt="" />
            </div>
            <div className="img3" data-aos="fade-left">
                <img src={img3} alt="" />
            </div>
        </div>
    </div>
  )
}

export default ServeComp