import React from "react";
import star from "../assets/Star2.svg";
import "./blog.scss";
import Slider from "react-slick";
import { useState, useEffect } from "react";
import { client } from "../client";
import { Link } from "react-router-dom";
import { useRef } from "react";

const Blogs = () => {
  const [blog, setBlog] = useState([]);
  const slider = useRef(null);
  useEffect(() => {
    client
      .fetch(
        `*[_type =="post"]{
            title,
            slug,
            body,
            mainImage{
              asset ->{
                _id,
                url
              }
            }
          }`
      )
      .then((data) => setBlog(data))
      .catch(console.error);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="blogs">
      <div className="upper">
        <img src={star} alt="" />
        <span>Our Blogs</span>
      </div>
      <div className="lower">
        <Slider {...settings} ref={slider}>
          {blog ? (
            blog.map((item, index) => {
              return (
                <div className="card" key={index}>
                  <Link to={`/blog/${item.slug.current}`}>
                  <div className="upper1">
                    <img
                      src={item.mainImage.asset.url}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="image_text">
                      <span>Read Full Blog</span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div>{item.title}</div>
                  </div>
                  </Link>
                </div>
              );
            })
          ) : (
            <span>error</span>
          )}
        </Slider>
        <div className="view">
          <button
            onClick={() => {
              slider.current.slickNext();
            }}
          >
            View more &#8594;
          </button>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
