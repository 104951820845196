import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import axios from "axios";
import vector from "../assets/vector.png";
import Footer from "../Components/Footer";
import star2 from "../assets/Star.svg";
import Slider from "react-slick";
import { storage } from "./firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";


const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [num1, setNum] = useState("");
  const [text, setText] = useState("");
  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const handleSubmit = async (e) => {
    if (document.myform.text.value == "" && document.myform.file.value == "") {
      alert("Both project description and project brief should not be empty!")
      e.preventDefault();
    }
    else{
      e.preventDefault();
      if(file.size/1024 > 5120){
        alert("File size Exceed")
        return
      }
      if (file === null || fileUrl==='') {
        const data = {
          Name: name,
          Number: num1,
          Email: email,
          ProjectDesc: text,
          ProjectFile: fileUrl
        };
        console.log(data);
        axios
          .post("https://sheetdb.io/api/v1/ao0z8kqzn0ejl", data)
          .then((response) => {
            console.log(response);
            alert("Uploaded")
          });
        setFileUrl("");
        setEmail("");
        setName("");
        setNum("");
        setText("");
        setFile(null);
      } else {
        const fileRef = ref(storage, `ProjectFile/${name + v4()}`);
        await uploadBytes(fileRef, file)
          .then(() => {
            alert("uploaded");
          })
          .catch(console.error);
        await getDownloadURL(fileRef)
          .then((url) => {
            setFileUrl(url);
          })
          .catch(console.error);
      }
    }
    

  };
  useEffect(() => {
    if (fileUrl !== "") {
      const data1 = {
        Name: name,
        Number: num1,
        Email: email,
        ProjectDesc: text,
        ProjectFile: fileUrl,
      };
      console.log(data1);
      axios
        .post("https://sheetdb.io/api/v1/ao0z8kqzn0ejl", data1)
        .then((response) => {
          console.log(response)
        });
      setFileUrl("");
      setEmail("");
      setName("");
      setNum("");
      setText("");
      setFile(null);
    }
  }, [fileUrl]);
  const dis = true;
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);
  const Text = [
    {
      text: "Creative Thinker",
    },
    {
      text: "Problem Solvers",
    },
    {
      text: "Strategic Planners",
    },
  ];
  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: 300,
    cssEase: "linear",
    pauseOnHover: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="contactus">
      <Navbar />
      <div className="Text">
        <div className="head" data-aos="zoom-in">
          Let's
          <br />
          <span>
            CONNECT
            <img src={star2} alt="" data-aos="fade-left" />
          </span>
        </div>
      </div>
      <div className="team">
        <div className="heading">Get in Touch with</div>
        <div className="teampic"></div>
        <div className="commun">
          <div className="inner">
            <Slider {...settings}>
              {Text.map((item, index) => {
                return (
                  <div key={index}>
                    {/* <div className="imag"><img src={star} alt="" /></div>  */}
                    <span>{item.text}</span>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
      <div className="block">
        <div className="text">
          At Tackle, we believe that great communication is key to success.
          Whether you have a question, a comment, or are ready to start a
          project, we want to hear from you. <br />
          <br />
          Fill out our contact form, give us a call, or drop by our office - we
          look forward to connecting with you.
        </div>
      </div>
      <div className="form">
        <form action="" onSubmit={handleSubmit} name="myform">
          <input
            type="text"
            placeholder="Name"
            id="name"
            onChange={(event) => {
              setName(event.target.value);
            }}
            required
          />
          <input
            type="number"
            name=""
            id="number"
            placeholder="Contact Number"
            onChange={(event) => {
              setNum(event.target.value);
            }}
            required
          />
          <input
            type="email"
            name=""
            id="email"
            placeholder="Email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            required
          />
          <input
            type="text"
            name="text"
            id="text"
            placeholder="Project Description"
            onChange={(event) => {
              setText(event.target.value);
            }}
          />
          or
          Attach your Project Brief
          <input
            type="file"
            name="file"
            id="file"
            placeholder="Attach Project brief"
            onChange={(event) => {
              setFile(event.target.files[0]);
            }}
          />
          File size not more than 3 MB
          <button type="submit">
            Start a project <img src={vector} alt="" />
          </button>
        </form>
      </div>
      <Footer dis />
    </div>
  );
};

export default ContactUs;
