
import { initializeApp } from "firebase/app";
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyBhhxnMYwTAx2hKopSngS1pXN2AWV21POo",
  authDomain: "tackle-76ce0.firebaseapp.com",
  projectId: "tackle-76ce0",
  storageBucket: "tackle-76ce0.appspot.com",
  messagingSenderId: "1030527266907",
  appId: "1:1030527266907:web:dc53d5236c3185cb99cf7e"
};

// Initialize Firebasdepe
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)