import React, { useState } from 'react'
import logo from '../assets/logo.svg'
import { Link } from 'react-router-dom'
import vector from '../assets/vector.png'
import star from '../assets/Star.svg'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react'
import smallStar from '../assets/smallstar.svg'
import './Nav.scss'
import Navslider from './Navslider'

const Navbar = () => {
  let location = useLocation();
  const [deg, setDeg] = useState(false)
  const [active,setActive] =useState(false)
 
  useEffect(() => { 
  }, [location]);

  const handleClick =()=>{
    var q=document.getElementById("slide")
    q.classList.toggle("openSlide")
    if(deg===false){  
      setDeg(true)
      const ele2=document.getElementById("slide")
      ele2.style.display= "flex"
      ele2.style.animation = "down1 1s";
      setActive(true)
    }
    else{    
      const ele2=document.getElementById("slide")
      ele2.style.animation = "upward .7s"
      setDeg(false)
      setTimeout(() => {    
        ele2.style.display="none"    
      }, 300);
      setActive(false)   
    }
    
  }
  return (
    <div className='Navbar'>
        <div className="left">
            <Link to="/"><img src={logo} alt="" /></Link>
        </div>
        <div className="right">
            <Link to='/'>Home</Link>
            <Link to='/AboutUs'>About Us</Link>
            <Link to='/Service'>Services</Link>
            <Link to='/Project'>Projects</Link>
            <Link to='/JoinUs'>Join Us</Link>
            <Link to='/ContactUs'>Contact us</Link>
            <img src={star} alt="" className={`star ${location.pathname==="/"? "home" : ""}${location.pathname==="/AboutUs"? "about" : ""}
            ${location.pathname==="/Service"? "service" : ""}
            ${location.pathname==="/Project"? "project" : ""}
            ${location.pathname==="/JoinUs"? "join" : ""}
            ${location.pathname==="/ContactUs"? "contact" : ""}`} />
            <img src={vector} alt="" className='vector'/>
        </div>
        <div className="respRight">
        <div className="img-div"  id="img-div">
          <img src={smallStar} alt="" className={`inner ${active?"openInner":""}`} id='inner' onClick={handleClick}/>
        </div>
        </div>
        <Navslider/>
    </div>
  )
}

export default Navbar